#map {
  height: 600px;
}

#buttons {
  width: 100%;
}

#map {
  height: 600px;
}
